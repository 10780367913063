/* Reset CSS */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

/* Add box sizing and border box to include padding and border in the element's total width and height */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Set body line height to 1 */
body {
  line-height: 1;
}

/* Grouping content */
ol,
ul {
  list-style: none;
}

/* Set block quotes and q tags */
blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

/* Set table styles */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* src/styles.css */
body {
  font-family: 'Droid Sans Mono', monospace;
  /* padding-bottom: 120px; */
}

footer {
  width: 100%;
  height: 80px;
}

.App {
  display: flex;
  flex-direction: column;
}

.fc-daygrid-day-top {
  flex-direction: column !important;
}

.day-hover {
  transition: background-color 0.3s ease-in-out;
}

.day-hover:hover {
  background-color: rgba(192, 202, 51, 0.3);
}

thead[role="presentation"] {
  background-color: darkgray;
  border-radius: 10px;
}

.menu-item {
  cursor: pointer;
  font-size: 2rem;
}

.menu-item:hover {
  color: rgba(192, 202, 51);
  text-shadow: 0 0 10px currentColor;
  /* Glow effect on hover */
}

.customHoverEffect {
  color: #fff;
}

.customHoverEffect:hover {
  color: rgba(192, 202, 51);
  text-shadow: 0 0 10px currentColor;
  /* Glow effect on hover */
}

/* styles.css */
.homeCardDivMargin {
  margin: 4rem;
}

@media (max-width: 800px) {
  .homeCardDivMargin {
    margin: 1rem;
  }
}

@media (max-width: 800px) {

  .fc-daygrid-event,
  .fc-timegrid-event {
    overflow: hidden;
  }

  .fc-event-title {
    font-size: smaller;
  }
}

.day-cell-mobile {
  --fc-daygrid-event-dot-width: 4px;
  /* Smaller dot size */
  max-height: 100px;
  /* Example fixed height, adjust as needed */
}

.desktop-calendar .fc .fc-header-toolbar {
  display: flex;
  justify-content: space-between;
}

.desktop-calendar .fc-header-toolbar .fc-toolbar-chunk:last-child {
  visibility: hidden;
}

.mobile-calendar .fc-header-toolbar .fc-toolbar-chunk:last-child {
  visibility: hidden;
}

.mobile-calendar .fc .fc-header-toolbar {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

}

.mobile-calendar .fc-toolbar-title {
  margin-bottom: 1rem;
}

/* LANDING PAGE STYLES */

.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15rem auto;
  height: 100vh;
  font-family: 'Press Start 2P', cursive;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.title,
.catchphrase,
.click-link {
  opacity: 0;
  /* Start with 0 opacity */
  animation-fill-mode: forwards !important;
  /* This will keep the element in the state of the last keyframe */
}

.title {
  animation: fadeIn 2s ease-in;
  font-size: 4rem;
}

.catchphrase {
  animation: fadeIn 2s ease-in 2s;
  margin: 0 0 6rem 0;
  text-transform: uppercase;
}

.click-link {
  cursor: pointer;
  display: flex;
  font-size: 2rem;
  text-decoration: none;
  color: rgba(192, 202, 51);
  /* text-shadow: 0 0 10px #fff; */

  /* Start with 0 opacity and hidden visibility */
  opacity: 0;
  visibility: hidden;

  /* Transition for visibility */
  transition: visibility 0s linear 4s, opacity 0s linear 4s;

  &:hover {
    color: rgba(192, 202, 51);
    text-shadow: 0 0 20px currentColor;
  }
}

.click-link.visible {
  opacity: 1;
  visibility: visible;
}


@keyframes blinkArrows {

  0%,
  100% {
    font-weight: 100;
  }

  33% {
    font-weight: 900;
    text-shadow: 0 0 20px currentColor
  }

  66% {
    font-weight: 100;
  }
}

.arrows {
  animation: blinkArrows 2s infinite;
}

.arrow1 {
  animation-delay: 4.75s;
}

.arrow2 {
  animation-delay: 5s;
}

.arrow3 {
  animation-delay: 5.25s;
}


@media (max-width: 600px) {

  /* Adjust the max-width as needed for your target devices */
  .landing-container {
    margin: 5rem auto;
    /* Adjust top margin for mobile */
  }

  .title {
    font-size: 2.5rem;
    /* Reduce the font size for mobile */
    margin-bottom: 3rem;
    /* Adjust the margin to reposition the title */
  }

  .catchphrase {
    text-align: center;
    /* Center the catchphrase text */
    font-size: 1.3rem;
    /* Optionally adjust the font size for mobile */
    margin: 0 0 3rem 0;
  }

  .click-link {
    flex-direction: column;
    /* Stack the text and arrows vertically */
    align-items: center;
    /* Center align items */
    text-align: center;
    font-size: 1.2rem;
    color: rgba(192, 202, 51);
  }

  .arrows {
    display: block;
    /* Make arrows stack vertically */
    margin: 0.5rem 0;
    /* Add some space between the arrows */
  }

  .arrows-container {
    display: flex;
  }
}

/* @media (max-width: 1499px) {
  .fc-daygrid-day-events {
    display: none !important;
  }

} */

/* .fc .fc-daygrid-day {
  
  width: auto;
} */

/* @media (min-width: 768px) {
  .fc .fc-daygrid-day {
    height: 140px !important;
    width: auto;
  }
} */